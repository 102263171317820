@font-face {
    font-family: 'CustomFont';
    src: url('../public/berliant/Berliant.ttf') format('truetype'),
        /* Adjust the path */
        url('../public/berliant/Berliant.otf') format('opentype');
    /* Adjust the format */
    font-weight: normal;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;